import { useEffect, useRef, useState } from "react";

const HeatmapChart = ({ data, metaConfig }) => {
  const chartRef = useRef();
  const [_, setUpdateState] = useState(0);
  const rebuildChart = () => setUpdateState((i) => i + 1);

  const cellHeight = chartRef.current?.offsetWidth / metaConfig.rowSize;

  useEffect(() => {
    rebuildChart();
    window.addEventListener("resize", rebuildChart);
  }, rebuildChart);

  return (
    <div ref={chartRef}>
      {data.map((row, i) => (
        <div key={i} className="flex" style={{ height: `${cellHeight}px` }}>
          {row.map((d, j) => (
            <Square key={j} color={d.color} />
          ))}
        </div>
      ))}
    </div>
  );
};

const Square = ({ color }) => {
  return (
    <div
      style={{
        backgroundColor: `${color}`,
        margin: 3,
      }}
      className="w-full"
    ></div>
  );
};

export default HeatmapChart;
