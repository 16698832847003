import React from 'react';
import { useCallback, useEffect, useRef } from "react";
import { select as d3Select } from "d3-selection";
import { scaleLinear as d3ScaleLinear } from "d3-scale";

const GroupedBarChartSummary = (props) => {
    const { data, metaConfig } = props;
    const chartRef = useRef(null);
    const tooltipRef = useRef(null);

    const drawChart = useCallback(() => {
        if (chartRef.current) {
          const { width, height } = chartRef.current.getBoundingClientRect();
          const { types, valueKey, colors, labelKey } = metaConfig;
          let barHeight = 15,
            gapBetweenGroups = 10,
            spaceForLabels = 25,
            transformedData = data.flatMap((d) =>
              types.map((t) => ({
                value: d[valueKey][t.field],
                type: t.label,
                label: d[labelKey],
              }))
            );
          barHeight = metaConfig.barHeight || barHeight;
          spaceForLabels = metaConfig.spaceForLabels || spaceForLabels;
          const groupHeight = barHeight * colors.length,
            xScale = d3ScaleLinear()
              .domain([0, 1])
              .range([spaceForLabels, width - spaceForLabels]);

          if (chartRef.current) {
            const { colors } = metaConfig;
            const svg = d3Select(chartRef.current)
              .append("svg")
              .attr("width", width)
              .attr("height", height);
            var bar = svg
              .selectAll("g")
              .data(transformedData)
              .enter()
              .append("g")
              .attr("transform", function (_d, i) {
                return (
                  "translate(" +
                  spaceForLabels +
                  "," +
                  (i * barHeight +
                    gapBetweenGroups * (0.5 + Math.floor(i / colors.length))) +
                  ")"
                );
              });
            const onMouseOver = function () {
              d3Select(tooltipRef.current).style("opacity", 1);
            };
    
            const onMouseMove = function (ev, d) {
              const { layerX, layerY } = ev;
              d3Select(tooltipRef.current)
                .style("opacity", 1)
                .html(`${d.label}<br/>${d.type}: ${(d.value * 100).toFixed(1)}%`)
                .style("top", `${layerY}px`)
                .style("left", `${layerX + 15}px`);
            };
    
            const onMouseLeave = function () {
              d3Select(tooltipRef.current).style("opacity", 0);
            };
    
            bar
              .append("rect")
              .attr("fill", function (_d, i) {
                return colors[i % colors.length];
              })
              .attr("class", "bar")
              .attr("width", ({ value }) => xScale(value))
              .attr("height", barHeight - 1)
              .on("mouseover", onMouseOver)
              .on("mousemove", onMouseMove)
              .on("mouseleave", onMouseLeave);
    
            // Add text label in bar
            bar
              .append("text")
              .attr("x", function ({ value }) {
                return xScale(value) - 50;
              })
              .attr("y", barHeight / 2)
              .attr("fill", "black")
              .attr("stroke", "black")
              .attr("stroke-width", "0.02rem")
              .attr("dy", ".30em")
              .text(({ value }) => `${(value * 100).toFixed(1)}%`);
          }
        }
    }, [data, metaConfig]);

    const destroyChart = useCallback(() => {
        if (chartRef.current) {
          d3Select(chartRef.current).select("svg").remove();
        }
    }, []);
    
    const rebuildChart = useCallback(() => {
        destroyChart();
        drawChart();
    }, [destroyChart, drawChart]);

    useEffect(() => {
        drawChart();
        window.addEventListener("resize", rebuildChart);
        return () => {
          destroyChart();
          window.removeEventListener("resize", rebuildChart);
        };
    }, [drawChart, destroyChart, rebuildChart]);
    return (
        <React.Fragment> 
            <div
            ref={chartRef}
            style={{ width: "100%", height: "100%", position: "relative" }}
            >
                <div ref={tooltipRef} className="tooltip"></div>
            </div>   
        </React.Fragment>
    )
}

export default GroupedBarChartSummary;