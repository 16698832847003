import { useState } from 'react'
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"

import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'
import SaveIcon from '@mui/icons-material/Save'

import UserAction from 'redux/modules/user/actions'
import AppActions from 'redux/modules/app/actions'

import GrayTextField from 'components/form-utilities/gray-text-field'

import { makeStyles } from '@material-ui/core/styles';
import WhiteTextField from 'components/form-utilities/white-text-field';
import { useNavigate } from 'react-router-dom';


import {Box, Grid, InputLabel, MenuItem, Select, List, ListItem, Pagination, Breadcrumbs,IconButton, Link, Input, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TablePagination, Paper, TableSortLabel,
    Modal
} from '@mui/material';
import { Close } from '@mui/icons-material';

const useStyles = makeStyles({
	forgetPass:{
		fontSize: '14px',
		lineHeight: '100%',
		margin: '0 !important',
		padding: '0 !important',
		color: '#fff',
		textTransform: 'none !important'
	},
	login:{
		backgroundColor: '#F3403C !important',
		width: '100%',
		borderRadius: '0 !important',
		textTransform: 'none !important',
		boxShadow: 'none !important'
	}

})
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 500,
	// bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 2,
	borderRadius: '8px',
	bgcolor: '#f5f5f5',
  };

function ForgotUsernameModal ({ item, onClose }) {


    return <Modal
    open={item}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <Typography variant="h6" component="h2" fontWeight="600" marginBottom="30px" textAlign="center">
	  	Forgot Your Username?
      </Typography>
	  	<Typography id="modal-description" sx={{ mt: 2 }}>
		  	Please check your email for messages with subject 
			lines starting with "Your Reports are Ready!" from{' '}
			<Link
              href="mailto:surveys@workforcerg.com"
              sx={{ color: 'blue', textDecoration: 'none' }}
            >
			<Typography>surveys@workforcerg.com.</Typography>
            </Link>{' '}
          </Typography>
		  <Typography sx={{ mt: 2 }}>
			Look for the username needed to log in, which can be found in the red text, 
			point number 2 within the email body, below the Feedback Data Dashboard link. 
          </Typography>
          <Typography  sx={{ mt: 2 }}>
		  		If you are unable to find the username, contact {' '}
            <Link 
              href="mailto:support@workforcerg.com"
              sx={{ fontSize: '1rem !important', display: 'inline-block' }}
            >
              support@workforcerg.com
            </Link>{' '}
            for help.
          </Typography>
		  <Typography sx={{ mt: 2 }}>Thank you!</Typography>
		  <Typography sx={{ mt: 2 }}>Workforce Research Group</Typography>
          <Box sx={{ textAlign: 'right', mt: 4 }}>
            <Button onClick={onClose} variant="contained">
              Close
            </Button>
          </Box>
    </Box>
  </Modal>
}
 
const ClientAuthForm = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()
	const { http } = global.services
	const [ state, setState ] = useState({
		username: '',
		submitting: false,
		usernameError:false,
		passwordError:false,
		error: null
	})

	const [showModal, setShowModal] = useState(false);
	const [showOrgMessage, setShowOrgMessage] = useState(false);
	const [showEmail, setShowEmail] = useState(false);

	const handleSubmission = async (event = null, forAdminLogin = false, email = null) => {
		if (event)
			event.preventDefault()

		if(state.username){
			setState(prevState => ({ ...prevState, submitting: true, error: null, usernameError: false, passwordError: false }))

			const payload = {
				username: state.username.trim(),
				userEmail: email
			}

			try {
				const response = await http.post('/user/login', payload)
				const responseData = response.data.data
				dispatch(UserAction.setUser({...responseData, user: responseData.userData }))
				if(responseData.userData?.organizationProgram?.length > 0){
					// const program = responseData.userData?.organizationProgram?.slice(-1)[0];
					const organizationprogramId = responseData.userData?.organizationprogramId;
					var { organizationProgram } = responseData.userData ? responseData.userData : responseData.user;

					let dealId = responseData?.userData?.dealId || null;
					let defaultProgramId = null; 
					if(organizationprogramId || dealId){
						defaultProgramId = organizationProgram.find(item => item._id === organizationprogramId);
						if(!defaultProgramId?.programId?._id){
							defaultProgramId = organizationProgram.find(item => item?.DealId === dealId);
						}
					}
					console.log("************************");
					// console.log(defaultProgramId);
					// const program = responseData.userData?.organizationProgram?.find(orgProg => orgProg['_id'] === organizationprogramId);
					if(organizationProgram){
						dispatch(AppActions.selectProgram(defaultProgramId?.programId?._id || organizationProgram[0]?.programId?._id));
					}
					// if(program) dispatch( AppActions.selectProgram(program.programId?._id) );
					else dispatch( AppActions.selectProgram(responseData.userData?.organizationProgram[0]?.programId?._id));
				}

				history.push('/dashboard')
			} catch (error) {
				setShowEmail(false)
				const response = error.response;
				console.log('Authentication Error: ', response);
				let defaultErrorMsg = 'An unknown error occured. Please refresh the page and try again.'
				const stateToReplace = state

				if (error?.response) {
					stateToReplace.submitting = false
					stateToReplace.error = { title: 'Unknown Error', message: defaultErrorMsg }
					stateToReplace.usernameError = true
					stateToReplace.passwordError = true
					if(error?.response?.status == 404) {
						stateToReplace.error = { title: 'Invalid Credentials', message: error?.response?.data?.message }
					}
				}

				setState(stateToReplace)
			}
		}
	}

	return (
		<>
		{/* <form className="w-1/2" onSubmit={ (event) => handleSubmission(event) }> */}
		<div className="w-1/2">
			{
				state.error && (
					<div className="mb-10">
						<Alert severity="error">
							<AlertTitle>{ state.error?.title }</AlertTitle>
							{ state.error?.message }
						</Alert>
					</div>
				)
			}
			<Typography
				component="h1"
				fontSize="30px"
				fontWeight="700"
				marginBottom="30px"
				color="#fff"
			>
				Log In
			</Typography>

			<div className="input-group mb-5">
				<Typography
					component="h4"
					fontSize="14px"
					marginBottom="10px"
					color="#fff"
				>
					Username
				</Typography>
				<GrayTextField
					id="email-outlined-basic"
					className="w-full rounded-none"
					variant="outlined"
					placeholder="Enter your Username"
					onChange={ (event) => setState(prev => ({ ...prev, username: event.target.value })) }
					autoComplete="off"
					error={state.usernameError}
					onKeyPress={(e)=>
						{	 if (e.key === 'Enter') {
								if(state.username.trim().length > 0){ 
									setShowOrgMessage(true)
								}
							}	
						}
					}
				/>
			</div>

			<div className="flex flex-row mb-5">
				<LoadingButton
					variant="contained"
					className={classes.login}
					onClick={(e) => {
						if(state.username.trim().length > 0){ 
							setShowOrgMessage(true)
						}
					}}
					size={'large'}
					loadingPosition="start"
					startIcon={<></>} 
					loading={ state.submitting }
				>
					<span className={ state.submitting ? 'pl-5' : '' }>
						{ state.submitting ? 'Authenticating' : 'Log In' }
					</span>
				</LoadingButton>				
			</div>

			<div className="flex">
				<Button
					variant="text"
					color={'white'}
					size={'large'}
					className={classes.forgetPass}
					// onClick={() => history.push('/forgot-username')}
					onClick={() => setShowModal(true)}
				>
					Forgot Username?
				</Button>
			</div>
		</div>
<div className="mt-5 w-1/2">
	<Alert severity="info" className='h6' icon={false}>
		<AlertTitle>Sharing credentials</AlertTitle>
		We care about your organization’s security. Sharing credentials puts your organization’s data at risk. WRG strongly recommends that you don’t share credentials with anyone outside your organization.
	</Alert>
</div>
<ForgotUsernameModal item={showModal} onClose={() => setShowModal(false)}/>
<LoginOragnization item={showOrgMessage} onClose={() => setShowOrgMessage(false)} setShowEmail={()=>{
	setShowEmail(true);
	setShowOrgMessage(false);
}}/>
<EmailModal item={showEmail} onClose={() => setShowEmail(false)} handleSubmission={handleSubmission}
	classes={classes}
	state={state}
	/>
</>
	)

}

function LoginOragnization ({ item, onClose, setShowEmail }) {

    return <Modal
    open={item}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      {/* <Typography variant="h6" component="h2" fontWeight="600" marginBottom="30px" textAlign="center">
	  	Sharing Credentials
      </Typography> */}
	  	<Typography id="modal-description" sx={{ mt: 2 }}>
          </Typography>
		  <Typography sx={{ mt: 2, textAlign:"center" }}>
		  	Are you an employee or representative of this organization?
          </Typography> 
          <div className="flex flex-row justify-content-center gap-4 mt-5" style={{justifyContent:'center'}}> 
            <div>
			<Button onClick={()=>{window.location.href = 'https://workforcerg.com/';}} variant="contained" color="error">
              No
            </Button>
			</div>  
            <div>
			<Button onClick={setShowEmail} variant="contained">
              Yes
            </Button> 
			</div>
		  </div>
		  <Typography sx={{ mt: 2 }}>
		  	<Box sx={{fontSize:'10px', backgroundColor: 'lightgray',  border: '0.5px solid lightgray', padding: 1, borderRadius: '5px',}}> 
				We care about your organization’s security. Sharing credentials puts your organization’s data at risk. WRG strongly recommends that you don’t share credentials with anyone outside your organization.
			</Box>
          </Typography>
    </Box>
  </Modal>
}

function EmailModal ({ item, onClose,handleSubmission,state,classes }) {
	const [ email, setEmail ] = useState('');
	const [error, setError] = useState('');
    
	const submitWithEmail = (event = null, forAdminLogin = false)=>{
		event.preventDefault();
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if(email.trim().length > 0){ 
			if (emailRegex.test(email)) {
				setError('');
				handleSubmission(event, forAdminLogin, email)// Clear error if email is valid
			  } else {
				setError('Please double check your email address and enter a valid email');
			  }
		}
	}

	const handleForm = ({target}) => { 
		setEmail(target.value)
	}
	return <Modal
	open={item}
	onClose={()=>onClose()}
	aria-labelledby="role-delete-title"
	aria-describedby="role-delete-description"
  >
	
	<form onSubmit={(event)=>submitWithEmail(event)}>
	<Box sx={style}>
	  <Typography id="role-form"
		  variant="h6"
		  component="span"
		  fontWeight="600"
		  display="block"
		  className="text-center mt-6"
	  >
		  <IconButton
		  aria-label="close"
		  onClick={()=>onClose()}
		  sx={{ position: 'absolute', right: 8, top: 8 }}
		>
		  <Close />
		</IconButton>
	  	Email
	  </Typography>
	  <Box>  
	  <WhiteTextField 
			id="email"
			className="w-full my-2 white-field"
			variant="outlined"
			placeholder="Enter Email"
			value={ email }
			name="email"
			onChange={ handleForm }
			autoComplete="off"
			required
		/>
	  </Box> 
	  {error && <p style={{ color: 'red', fontSize:'12px', textAlign:"center", marginBottom:'1.5rem' }}>{error}</p>}
	  <Box className="flex flex-col">
		  {/* <Button
			  variant="contained"
			//   onClick={handleSubmit}
			  color="error"
			  className="normal-case rounded-none"
			  sx={{ margin: "15px 0"}}
		  >
			  Continue Log In
		  </Button> */}

		<LoadingButton
			variant="contained"
			className={classes.login}
			onClick={submitWithEmail}
			size={'large'}
			loadingPosition="start"
			startIcon={<></>}
			loading={ state.submitting }
		>
				<span className={ state.submitting ? 'pl-5' : '' }>
					{ state.submitting ? 'Authenticating' : 'Continue Log In' }
				</span>
			</LoadingButton>	
		  <Button
			  variant="text"
			  onClick={()=>onClose()}
			  className="normal-case rounded-none block text-center"
		  >
			  Cancel
		  </Button>
	  </Box>
	</Box>
	  </form>
  </Modal>
}

export default ClientAuthForm
