import { useCallback, useEffect, useRef } from "react";
import { select as d3Select } from "d3-selection";
import {
  range as d3Range,
  rollup as d3Rollup,
  extent as d3Extent,
} from "d3-array";
import { stack as d3Stack, stackOrderNone, stackOffsetExpand } from "d3-shape";
import { scaleLinear, scaleBand, scaleOrdinal } from "d3-scale";
import { axisLeft, axisTop } from "d3-axis";
const StackedBarModifiedChart = (props) => {
  const { data, metaConfig } = props;

  const chartRef = useRef(null);
  const tooltipRef = useRef(null);

  const drawChart = useCallback(() => {
    if (chartRef.current) {
      const yPadding = 0.4,
        barHeight = 40;
      const margin = {
        top: 10,
        right: 20,
        left: 310,
        bottom: 0,
      };
      const { width } = chartRef.current.getBoundingClientRect(),
        chartWidth = width - margin.left - margin.right;
      const { fields, labelKey = 'label', valueKey = 'value', groupKey = 'group', colors } = metaConfig,
        transformedData = fields.flatMap(({ key }) =>
          data.map((d) => ({
            [labelKey]: d[labelKey],
            [valueKey]: d[key],
            [groupKey]: key,
          }))
        );
      //
      const chartHeight = (data.length * fields.length) * barHeight - margin.top - margin.bottom;
      const X = transformedData.map((d) => d[valueKey]);
      const Y = transformedData.map((d) => d[labelKey]);
      const Z = transformedData.map((d) => d[groupKey]);
      const I = d3Range(X.length);
      const zDomain = fields.map((field) => field.key);
      const xRange = [margin.left, margin.left + chartWidth];
      const yRange = [chartHeight - margin.top, margin.top];
      const series = d3Stack()
        .keys(zDomain)
        .value(([, I], z) => X[I.get(z)])
        .order(stackOrderNone)
        .offset(stackOffsetExpand)(
          d3Rollup(
            I,
            ([i]) => i,
            (i) => Y[i],
            (i) => Z[i]
          )
        )
        .map((s) =>
          s.map((d) => Object.assign(d, { i: d.data[1].get(s.key) }))
        );

      const xDomain = d3Extent(series.flat(2));
      const xScale = scaleLinear(xDomain, xRange);
      const yScale = scaleBand(Y, yRange).paddingInner(yPadding);
      const color = scaleOrdinal(zDomain, colors);
      const xAxis = axisTop(xScale).ticks(chartWidth / 80, "%");
      const yAxis = axisLeft(yScale).tickSizeOuter(0);

      if (chartRef.current) {
        const svg = d3Select(chartRef.current)
          .append("svg")
          .attr("width", chartWidth + margin.left + margin.right)
          .attr("height", chartHeight + margin.top + margin.bottom);

        const onMouseOver = function () {
          d3Select(tooltipRef.current).style("opacity", 1);
        };

        const wrap = (text, width) => {
          text.each(function() {
            var text = d3Select(this),
                words = text.text().split(/\s+/).reverse(),
                word,
                line = [],
                lineNumber = 0,
                lineHeight = 1, // ems
                y = text.attr("y"),
                dy = parseFloat(text.attr("dy")),
                tspan = text.text(null).append("tspan").attr("x", -8).attr("y", y).attr("dy", dy + "em");
            while (word = words.pop()) {
              line.push(word);
              tspan.text(line.join(" "));
              if (tspan.node().getComputedTextLength() > width) {
                line.pop();
                tspan.text(line.join(" "));
                line = [word];
                tspan = text.append("tspan").attr("x", -8).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
              }
            }
          });
        }

        const onMouseMove = function (ev, d) {
          const { layerX, layerY } = ev;
          const { fields, colors } = metaConfig;
          const valuesHtml = fields
            .map(
              (field, i) =>
                `<div><span style="color: ${colors[i]}">${
                  field.label
                }</span>: ${d.data[1].get(field.key)}</div>`
            )
            .join("");
          d3Select(tooltipRef.current)
            .style("opacity", 1)
            .html(`${d.data[0]}<br/>${valuesHtml}`)
            .style("top", `${layerY}px`)
            .style("left", `${layerX + 15}px`);
        };

        const onMouseLeave = function () {
          d3Select(tooltipRef.current).style("opacity", 0);
        };
        //Bar
        svg
          .append("g")
          .selectAll("g")
          .data(series)
          .join("g")
          .attr("fill", ([{ i }]) => color(Z[i]))
          .selectAll("rect")
          // .call((rect) => console.log('rectangle', rect))
          .attr("class", "bar")
          .data((d) => d)
          .join("rect")
          .attr("x", ( [x1, x2],b,c) => {
            return xScale(0) //Math.min(xScale(x1), xScale(x2))
          })
          // .attr("x", ({i}) => xScale(i))

          .attr("y", ({ i }, b, c) => {
            return yScale(Y[i]) + ( i < c.length ? 0 : 27.5);
          })
          // .attr("y", (a,c,d) => console.log(a,yScale(Y[a.i])))
          .attr("width", ([x1, x2]) => Math.abs(xScale(x1) - xScale(x2)))
          // .attr("width", ([x1,x2],y,c,d) => console.log('width',x1,x2,y,c,d))
          .attr("height", yScale.bandwidth() / fields.length)

          // .on("mouseover", onMouseOver)
          // .on("mousemove", onMouseMove)
          // .on("mouseleave", onMouseLeave);
          //Bar Labels
        svg
          .append("g")
          .selectAll("g")
          .data(series)
          .join("g")
          .attr("fill", "white")
          .selectAll("text")
          .data((d) => d)
          .join("text")
          .text(([s, e]) =>
            e - s > 0.0 ? `${((e - s) * 100).toFixed()}%` : ""
          )
          .attr("stroke", "black")
          .attr("stroke-width", "0.0rem")
          .attr(
            "x",
            ([x1, x2]) =>
              Math.min(xScale(x1), xScale(x2)) +
              Math.abs(xScale(x1) - xScale(x2)) / 2 -
              10
          )
          .attr(
            "y",
            ({ i }, b, c) => {
              console.log('abc', b, c, yScale(Y[i]) + (yScale.bandwidth() / fields.length) / 2 + 5)
              return yScale(Y[i]) + (yScale.bandwidth() / fields.length) / 2 + 5
            }
          )
          // .on("mouseover", onMouseOver)
          // .on("mousemove", onMouseMove)
          // .on("mouseleave", onMouseLeave)

        // .attr("width", ([x1, x2]) => Math.abs(xScale(x1) - xScale(x2)))
        // .attr("height", yScale.bandwidth());
        //Bottom bar
        svg
          .append("g")
          .attr("transform", `translate(0,${margin.top + chartHeight})`)
          .call(xAxis)
          .call((g) => g.select(".domain").remove())
          .call((g) => g.selectAll(".tick line").remove())
        // .call((g) =>
        //   g
        //     .append("text")
        //     .attr("x", chartWidth - margin.right)
        //     .attr("y", -22)
        //     .attr("fill", "currentColor")
        //     .attr("text-anchor", "end")
        //     .text("xLabel")
        // );
        svg
          .append("g")
          .attr("transform", `translate(${xScale(0)},0)`)
          .call(yAxis)
          .call((g) => g.select(".domain").remove())
          .call((g) => g.selectAll(".tick line").remove())
          .selectAll(".tick text")
          .call(wrap, margin.left);
      }
    }
  }, [data, metaConfig]);

  const destroyChart = useCallback(() => {
    if (chartRef.current) {
      d3Select(chartRef.current).select("svg").remove();
    }
  }, []);

  const rebuildChart = useCallback(() => {
    destroyChart();
    drawChart();
  }, [destroyChart, drawChart]);

  useEffect(() => {
    drawChart();
    window.addEventListener("resize", rebuildChart);
    return () => {
      destroyChart();
      window.removeEventListener("resize", rebuildChart);
    };
  }, [drawChart, rebuildChart, destroyChart]);

  return (
    <div
      ref={chartRef}
      style={{ width: "100%", height: "100%", position: "relative" }}
    >
      <div ref={tooltipRef} className="tooltip"></div>
    </div>
  );
};

export default StackedBarModifiedChart;
