import { useCallback, useEffect, useRef } from "react";
import { select as d3Select } from "d3-selection";
import { arc as d3Arc } from "d3-shape";

const ProgressBar = (props) => {
  const { data, metaConfig } = props;
  const chartRef = useRef(null);

  const drawChart = useCallback(() => {
    if (chartRef.current) {
      const { width, height } = chartRef.current.getBoundingClientRect();
      const BAR_WIDTH = 0.15,
        OUTER_WIDTH = 0.05,
        PIx2 = 2 * Math.PI,
        barRadius = (Math.min(height, width) / 2) * 0.8;
      const arc = d3Arc(),
        outerArc = d3Arc();

      arc
        .outerRadius(barRadius * 0.85)
        .innerRadius(barRadius * (0.85 - BAR_WIDTH));
      outerArc
        .outerRadius(barRadius * 0.92)
        .innerRadius(barRadius * (0.92 - OUTER_WIDTH));
      const svgG = d3Select(chartRef.current)
        .append("svg")
        .attr("height", height)
        .attr("width", width)
        .append("g")
        .attr("transform", `translate(${width / 2}, ${height / 2})`);

      const arcData = arc({
        startAngle: 0,
        endAngle: data[metaConfig.valueKey] * PIx2,
      });
      svgG.append("path").attr("d", arcData).attr("fill", metaConfig.color);

      svgG
        .append("text")
        .text(`${data[metaConfig.valueKey] * 100}%`)
        .attr("fill", metaConfig.color)
        .attr("y", barRadius * 0.05)
        .style("text-anchor", "middle")
        .style("font-size", `${barRadius * 0.2}px`);

      if (metaConfig.title) {
        svgG
          .append("text")
          .text(metaConfig.title)
          .attr("y", barRadius * 0.15)
          .style("text-anchor", "middle")
          .style("font-size", `${barRadius * 0.1}px`);
      }

      if (metaConfig.subTitle) {
        svgG
          .append("text")
          .text(metaConfig.subTitle)
          .attr("fill", "grey")
          .attr("y", barRadius * 0.25)
          .style("text-anchor", "middle")
          .style("font-size", `${barRadius * 0.1}px`);
      }

      if (data.extraLayer) {
        const outerArcAngles = {
          startAngle: 0,
          endAngle: data.extraLayer[metaConfig.valueKey] * PIx2,
        };
        const outerArcData = outerArc(outerArcAngles);
        svgG
          .append("path")
          .attr("d", outerArcData)
          .attr("fill", metaConfig.extraColor);
        if (!metaConfig.hideLabels) {
          svgG
            .append("polyline")
            .style("fill", "none")
            .attr("stroke-width", 1)
            .attr("stroke", metaConfig.extraColor)
            .attr("points", () => {
              var posA = outerArc.centroid(outerArcAngles);
              var posB = posA.map((p) => p * 1.1);
              return [posA, posB];
            });

          svgG
            .append("text")
            .text(`${data.extraLayer[metaConfig.valueKey] * 100}%`)
            .attr("transform", () => {
              var pos = outerArc.centroid(outerArcAngles).map((p) => p * 1.35);
              return `translate(${pos})`;
            })
            .attr("fill", metaConfig.extraColor)
            .style("text-anchor", "middle")
            .style("font-size", `${Math.min(50, barRadius * 0.2)}px`);
        }
      }
    }
  }, [data, metaConfig]);

  const destroyChart = useCallback(() => {
    if (chartRef.current) {
      d3Select(chartRef.current).select("svg").remove();
    }
  }, []);
  const rebuildChart = useCallback(() => {
    destroyChart();
    drawChart();
  }, [destroyChart, drawChart]);

  useEffect(() => {
    drawChart();
    window.addEventListener("resize", rebuildChart);
    return () => {
      destroyChart();
      window.removeEventListener("resize", rebuildChart);
    };
  }, [drawChart, destroyChart, rebuildChart]);

  return <div ref={chartRef} style={{ width: "100%", height: "100%" }}></div>;
};

export default ProgressBar;
